<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <logo />
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('Forgot Password?') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Enter your username and we\'ll send you instructions to reset your password') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="formRules"
            #default="{invalid}"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('Username')"
                label-for="forgot-password-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                <span v-if="!loading">{{ $t('Send reset link') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" />{{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import api from '@/apis/api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import { coreMethods } from '@/mixins'
import Logo from '@/views/modules/core/partials/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [coreMethods],
  data() {
    return {
      loading: false,
      username: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.loading = true
          api.forgotPassword({
            username: this.username,
          }).then(() => {
            this.loading = false
            this.showSuccessNotification(this.$t('Instructions to reset your password sent'))
            this.username = ''
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
